/* width */
.sidebar::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.custom-otp-input:focus {
  /* border-color: #ff0000 !important; */
  outline: #3669c9 !important;
  border: 1px solid #3669c9 !important;
}
.error-custom-otp-input:focus {
  /* border-color: #ff0000 !important; */
  outline: #f23836 !important;
  border: 2px solid #f23836 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
